<template>
  <div class="container">
    <nav v-show="loggedIn" class="white">
      <router-link to="/website" title="Website">Website</router-link>
      <router-link to="/general" title="General">General</router-link>
      <router-link to="/professionals" title="Professionals"
        >Professionals</router-link
      >
      <router-link to="/employees" title="Employees">Employees</router-link>
      <router-link to="/employers" title="Employers">Employers</router-link>
      <router-link to="/missions" title="Missions">Missions</router-link>
      <router-link to="/feedback" title="Feedback">Feedback</router-link>
      <router-link to="/database" title="Database">Database</router-link>
    </nav>
    <router-view :tileUnit="unit" />
  </div>
</template>

<script>
import Tile from "@/components/Tile.vue";

export default {
  name: "DashboardMain",

  components: {
    Tile,
  },

  props: {
    unit: { type: String, default: "percentage" },
  },

  data() {
    return {
      loggedIn: false,
    };
  },

  created() {
    document.addEventListener("localdatachanged", this.localDataChanged);
    this.localDataChanged();
  },

  destroyed() {
    document.removeEventListener("localdatachanged", this.localDataChanged);
  },

  methods: {
    localDataChanged() {
      this.loggedIn = window.localStorage.getItem("token") !== null || false;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 350px;
  margin-top: 1rem;
  margin-bottom: 5.5rem;
  flex-direction: column;
}

nav {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: fixed;
  top: 250;
}

/* Prevent 'jumping' text */
nav a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

nav a {
  display: inline-block;
  font-size: 15px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  margin: 0 2rem 2rem 2rem;
  padding: 0.2rem 0;
  border-bottom: 2px solid transparent;
  color: rgba(255, 255, 255, 0.5) !important;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

nav.white a:hover,
.link-active {
  color: white !important;
  font-weight: 700;
}

.link-active {
  border-color: white;
}
</style>

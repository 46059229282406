<template>
  <div
    :class="{
      tile: true,
      dark: isDark,
      light: !isDark,
      disabled: isDisabled,
    }"
  >
    <div
      :class="{
        tooltip: true,
        disabled: isDisabled,
      }"
      :title="tooltipValue"
    >
      <img svg-inline :src="infoIcon" alt="More info..." />
    </div>
    <p class="tileValue">{{ value }}</p>
    <p class="tileTitle" v-html="title"></p>
  </div>
</template>

<script>
export default {
  name: "Tile",

  props: {
    isDark: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    value: { type: String, default: "N/A" },
    tooltipText: { type: String, default: "" },
    title: { type: String, default: "-" },
  },

  computed: {
    infoIcon() {
      if (!this.isDisabled) {
        return require("@/assets/images/icons/Info.svg");
      }
      return this.isDark
        ? require("@/assets/images/icons/InfoDisabledDark.svg")
        : require("@/assets/images/icons/InfoDisabledLight.svg");
    },

    tooltipValue() {
      return this.isDisabled ? "" : this.tooltipText;
    },
  },
};
</script>
<style scoped lang="scss">
.tile {
  width: 200px;
  height: 145px;
  border-radius: 20px;
  border: none;
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.dark {
    background: rgba(0, 0, 0, 0.25);
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &.light {
    background: rgba(255, 255, 255, 0.35);

    &.disabled {
      color: rgba(255, 255, 255, 0.5);
      stroke-opacity: 0.5;
    }
  }

  .tileValue {
    font-weight: bold;
    font-size: 40px;
    margin: 20px;
    margin-top: 0px;
  }
  .tileTitle {
    font-weight: 500;
    font-style: italic;
    font-size: 15px;
  }
}

.tooltip {
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin: 10px;
  margin-left: 170px;

  &.disabled {
    cursor: auto;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import showSessionExpiredAlert from "@/utils.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/DashboardLogin.vue"),
  },
  {
    path: "/website",
    name: "Website",
    component: () => import("../views/DashboardWebsite.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general",
    name: "General",
    component: () => import("../views/DashboardGeneral.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/professionals",
    name: "Professionals",
    component: () => import("../views/DashboardProfessionals.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import("../views/DashboardEmployees.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employers",
    name: "Employers",
    component: () => import("../views/DashboardEmployers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/missions",
    name: "Missions",
    component: () => import("../views/DashboardMissions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("../views/DashboardFeedback.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/database",
    name: "Database",
    component: () => import("../views/DashboardDatabase.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

let router = new VueRouter({
  routes,
  linkActiveClass: "link-active",
  linkExactActiveClass: "link-exact-active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (window.localStorage.getItem("token") == null) {
      showSessionExpiredAlert();
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

let environmentAPI = process.env.VUE_APP_ENV_API;

export default {
  methods: {
    login: function (password, email) {
      return fetch(
        `${environmentAPI}/api/v1/dashboard/login?password=${btoa(
          password
        )}&email=${email}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .catch((error) => console.error(error));
    },

    logout: function () {
      return fetch(`${environmentAPI}/api/v1/dashboard/logout`, {
        method: "POST",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }),
      })
        .then((response) => response.json())
        .catch((error) => console.error(error));
    },
  },
};

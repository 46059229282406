import { render, staticRenderFns } from "./DashboardCalendar.vue?vue&type=template&id=24e36636&scoped=true&"
import script from "./DashboardCalendar.vue?vue&type=script&lang=js&"
export * from "./DashboardCalendar.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCalendar.vue?vue&type=style&index=0&id=24e36636&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e36636",
  null
  
)

export default component.exports
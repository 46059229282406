<template>
  <div class="calendarContainer">
    <span class="calendarLabel">{{ label }}</span>
    <datepicker
      :id="`datepicker${label}`"
      :ref="`datepicker${label}`"
      @selected="$emit('onSelectDate', label)"
      :value="dateValue"
      :disabled-dates="disabledDates"
      :format="'dd-MM-yy'"
      :input-class="'calendarInput'"
      :calendar-class="'calendarWrapper'"
    ></datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  name: "DashboardCalendar",

  components: {
    Datepicker,
  },

  props: {
    label: { type: String, default: "" },
    dateValue: {
      type: Date,
      default: function () {
        return new Date();
      },
    },
    disabledDates: {
      type: Object,
      default: {
        from: new Date(), // Disable all dates after specific date
        to: new Date(2022, 9, 1), // Disable all dates up to specific date
      },
    },
  },
};
</script>

<style scoped lang="scss">
.calendarContainer {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .calendarLabel {
    margin-right: 14px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    color: white;
  }
}
</style>

<template>
  <footer>
    <nav class="white">
      <span id="brand">ARBOREALL ® ALL RIGHTS RESERVED</span>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "DashboardFooter",
};
</script>

<style scoped lang="scss">
footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 3.2rem;
}

#brand {
  color: white !important;
  font-weight: 400;
  font-size: 12px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    tile: true,
    dark: _vm.isDark,
    light: !_vm.isDark,
    disabled: _vm.isDisabled,
  }},[_c('div',{class:{
      tooltip: true,
      disabled: _vm.isDisabled,
    },attrs:{"title":_vm.tooltipValue}},[_c('img',{attrs:{"svg-inline":"","src":_vm.infoIcon,"alt":"More info..."}})]),_c('p',{staticClass:"tileValue"},[_vm._v(_vm._s(_vm.value))]),_c('p',{staticClass:"tileTitle",domProps:{"innerHTML":_vm._s(_vm.title)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
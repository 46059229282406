<template>
  <div id="app">
    <dashboard-header @onToggleUnit="onToggleUnit" />
    <section id="base">
      <dashboard-main :unit="unit" />
      <dashboard-footer />
    </section>

    <div class="barDown"></div>
  </div>
</template>

<script>
import DashboardFooter from "@/components/DashboardFooter.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import DashboardMain from "@/components/DashboardMain.vue";

export default {
  components: {
    DashboardFooter,
    DashboardHeader,
    DashboardMain,
  },

  data() {
    return {
      unit: "fixed",
    };
  },

  methods: {
    onToggleUnit(unit) {
      this.unit = unit;
    },
  },
};
</script>
<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
}

.barDown {
  background-image: url("@/assets/images/Bars.png");
  background-attachment: fixed;
  padding: 0.45rem 0;
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
  bottom: 0;
  position: fixed;
  width: 100%;
}
</style>

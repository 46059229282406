import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import DashboardFooter from "@/components/DashboardFooter.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";

Vue.config.productionTip = false;

Vue.component("DashboardFooter", DashboardFooter);
Vue.component("DashboardHeader", DashboardHeader);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
